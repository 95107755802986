import React from 'react';
import PropTypes from 'prop-types';
import { platform, IOS, Button, Div, Group, Header, Card, CardGrid } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';


const osName = platform();

const Persik = props => (
	<Panel id={props.id}>
		<PanelHeader
			left={<PanelHeaderButton onClick={() => window.history.back()} data-to="home">
				{osName === IOS ? <Icon28ChevronBack /> : <Icon24Back />}
			</PanelHeaderButton>}
		>
			Persik
		</PanelHeader>
		<Div>
			<Button size='xl' onClick={props.downloadStickers}>Добавить стикеры</Button>
		</Div>
		<Group header={
			<Header>
				Стикеры из пака
			</Header>
		}>
			<Div className='card_grid'>
				{window.sticks_image[props.selectedPack[2]].map(
					img =>
					<Card size="m" mode="outline" className='stick_card'>
									<img className='stick_image' src={`${window.path_to_stick}/${props.selectedPack[2].toLowerCase()}/png/${img}`}/>
					</Card>
				)}
			</Div>
		</Group>
		{props.snackbar}
	</Panel>
);

Persik.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
};

export default Persik;

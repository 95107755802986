import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';

import { Gradient, CardScroll, Card, Header, Caption, Title, Subhead } from '@vkontakte/vkui'
import './Home.css';

const Home = props => (
	<Panel id={props.id}>
		<PanelHeader>Стикеры</PanelHeader>
		<Subhead weight='regular' className='caption' style={{marginTop: 10}}>Выбирай стикеры в меню!</Subhead>
		{Object.entries(window.sticks_data).map(
			data => {
				let [name, ...stickers] = data
				return <Gradient>
					<Group header={
						<Header>
							{name}
						</Header>
					}>
						<CardScroll style={{ paddingBottom: 20 }}>
							{stickers[0].map(
								
								row => 

									<Card size="s" mode="outline"
									onClick={() => {
										props.setSelectedPack(row)
										props.goToPage('stick_view')
										}}>
										<div className='stick_card'>
										<img className='stick_image' src={`${window.path_to_stick}/${row[2].toLowerCase()}/png/${row[3]}`}/>
										<div className='stick_name' style={{top: '50'}}>
											<Title  weight='medium' level='3'>{row[1]}</Title>
											<Subhead className='caption' weight='regular'> Количество: {row[4]}</Subhead>
										</div>
										</div>
									</Card>
							)}
						</CardScroll>
					</Group>
				</Gradient>
			}
		)}

	</Panel>
);

Home.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	fetchedUser: PropTypes.shape({
		photo_200: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		city: PropTypes.shape({
			title: PropTypes.string,
		}),
	}),
};

export default Home;
